import { NameRegex } from '@utils/Constant';
import { matchTagRegex } from '@utils/Helpers';
import * as Yup from 'yup';

export const deliveryAddressValidationSchema = Yup.object({
    deliveryAddress: Yup.object({
        firstName: Yup.string()
          .max(50, '50 characters maximum')
          .matches(NameRegex, "Enter valid characters")
          .required('First Name is required'),
        lastName: Yup.string()
          .max(50, '50 characters maximum')
          .matches(NameRegex, "Enter valid characters")
          .required('Last Name is required'),
        building: Yup.string()
          .test("building", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, "50 characters maximum"),
        unit: Yup.string()
          .test("unit", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, "50 characters maximum"),
        street: Yup.string()
          .test("street", "Enter valid characters", (value) => !matchTagRegex(value))
          .required("Address is required")
          .max(50, "50 characters maximum"),
        suburb: Yup.string()
          .test("suburb", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, "50 characters maximum"),
        city: Yup.string()
          .test("city", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(50, '50 characters maximum')
          .required('City is required'),
        postCode: Yup.string()
          .max(4, '4 characters maximum')
          .required('Postcode is required')
          .matches(/^[0-9]+$/, 'Must only contain numbers'),
        companyName: Yup.string()
          .test("companyName", "Enter valid characters", (value) => !matchTagRegex(value))
          .max(255, "255 characters maximum"),
    })
})